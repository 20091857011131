/* Emoji rain animation */
@keyframes emojiRain {
    0% {
      transform: translateY(-100%);
      opacity: 1;
    }
    100% {
      transform: translateY(100vh);
      opacity: 0;
    }
  }
  
  .emoji {
    position: absolute;
    top: -10%;
    animation: emojiRain linear infinite;
  }